import { template as template_b0cf7f8fad6a4d7da355000ebfe20bbd } from "@ember/template-compiler";
const FKLabel = template_b0cf7f8fad6a4d7da355000ebfe20bbd(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
