import { template as template_12901a4effc44fad8833261dd05e2737 } from "@ember/template-compiler";
const SidebarSectionMessage = template_12901a4effc44fad8833261dd05e2737(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
