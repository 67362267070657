import { template as template_bf53515a5b7e487ea714f0f04e7cf807 } from "@ember/template-compiler";
import icon from "discourse-common/helpers/d-icon";
const InputTip = template_bf53515a5b7e487ea714f0f04e7cf807(`
  <div
    class="tip {{if @validation.failed 'bad' 'good'}}"
    id={{@id}}
    ...attributes
  >
    {{#if @validation.reason}}
      {{icon (if @validation.failed "xmark" "check")}}
      {{@validation.reason}}
    {{/if}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default InputTip;
