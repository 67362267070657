import { template as template_69a4208f215e4d6ba15841420e494a31 } from "@ember/template-compiler";
const FKControlMenuContainer = template_69a4208f215e4d6ba15841420e494a31(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
